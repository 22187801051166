import React from "react";

export const GlobalContext = React.createContext(null);

export function getVat(value, date) {
  if (date > "2024-12-31T23:59:59.999Z") {
    return value * 1.18;
  }
  return value * 1.17;
}

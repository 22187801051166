import React, { Component } from "react";
import { Button, Col, Form, FormGroup, Label } from "reactstrap";

import Signature from "../Signature/Signature";

import { BlobProvider, PDFViewer } from "@react-pdf/renderer";

import PDF from "../CustomerPDF/pdf";

import api from "../../Services/api";
// import aes from 'crypto-js/aes';
// import encUtf8 from 'crypto-js/enc-utf8';

class CustomerForm extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSignatureChange = this.handleSignatureChange.bind(this);
    this.openSignatureDialog = this.openSignatureDialog.bind(this);
    this.closeSignatureDialog = this.closeSignatureDialog.bind(this);
    this.print = this.print.bind(this);
    this.renderPDF = this.renderPDF.bind(this);
    this.downloadPDF = this.downloadPDF.bind(this);
    this.send = this.send.bind(this);

    this.labels = Object.assign({}, require("./labels.json"));

    this.state = {
      orderId: undefined,
      customerSignature: undefined,
      orderDetails: undefined,
      companyDetails: {
        name: this.labels.companyDetails.name,
        address: this.labels.companyDetails.address,
        phone: this.labels.companyDetails.phone,
        fax: this.labels.companyDetails.fax,
        email: this.labels.companyDetails.email,
      },
      signature: {
        signatureData: undefined,
        signatureLoaded: undefined,
      },
      disableSendButton: false,
    };

    this.allItems = [];
    this.allItemTypes = [];
    this.allCompanies = [];
    this.totalPrice = 0;
  }

  calculateTotalPrice(items) {
    for (var i = 0; i < items.length; i++) {
      let currentItem = items[i];

      if (currentItem != undefined) {
        this.totalPrice += parseFloat(currentItem.price);
      }
    }
  }

  componentDidMount() {
    let pathname = window.location.pathname.split("/customerForm/");
    if (pathname.length == 2) {
      this.setState({ orderId: pathname[1] });

      let ordersRequest = api.orders.get({
        id: pathname[1],
      });
      // Currently this feature is not active

      // let customerSignatureRequest = api.signature.get({
      //   id: pathname[1]
      // });

      let itemsRequest = api.items.get({});
      let itemTypesRequest = api.itemTypes.get({});
      let companiesRequest = api.companies.get({});

      const ORDER_INDEX = 0;
      const ITEMS_INDEX = 1;
      const ITEM_TYPES_INDEX = 2;
      const COMPANIES_INDEX = 3;
      const CUSTOMER_SIGNATURE_INDEX = 4;

      Promise.all([
        ordersRequest,
        itemsRequest,
        itemTypesRequest,
        companiesRequest,
        // customerSignatureRequest
      ]).then((values) => {
        this.allItems = values[ITEMS_INDEX].data.items;
        this.allItemTypes = values[ITEM_TYPES_INDEX].data.item_types;
        this.allCompanies = values[COMPANIES_INDEX].data.companies;

        let data = values[ORDER_INDEX].data.orders[0].data;
        let seller_id = values[ORDER_INDEX].data.orders[0].seller_id;
        let surveyor_id = values[ORDER_INDEX].surveyor_id;
        let status = values[ORDER_INDEX].data.orders[0].status;
        this.loadData(data, seller_id, surveyor_id, status);
        this.calculateTotalPrice(data.Items);

        // Currently this feature is not active

        // if (
        //   values[CUSTOMER_SIGNATURE_INDEX].data &&
        //   values[CUSTOMER_SIGNATURE_INDEX].headers["content-length"] > 2
        // ) {
        //   this.loadSignature(values[CUSTOMER_SIGNATURE_INDEX].data);
        // }
      });
    }
  }

  loadData(dataToLoad, sellerId, surveyorId, status) {
    let orderDetailsObject = {
      data: dataToLoad,
      sellerId: sellerId,
      surveyorId: surveyorId,
      status: status,
    };
    this.setState({ orderDetails: orderDetailsObject });
  }

  loadSignature(data) {
    let customerSignatureData = `data:image/png;base64,${data}`;
    this.setState({ customerSignature: customerSignatureData });

    this.setState((prevState) => {
      let signature = Object.assign({}, prevState.signature);
      // signature["signatureData"] = data;
      signature["signatureLoaded"] = true;

      return { signature };
    });
  }

  updateCustomerSignature = (event) => {
    let data = { event: event };
    let pathname = window.location.pathname.split("/customerForm/");

    if (pathname.length == 2) {
      data["id"] = pathname[1];

      api.orders
        .update(data)
        .then((response) => {
          this.setState((prevState) => {
            let orderDetails = Object.assign({}, prevState.orderDetails);
            orderDetails.status = "CUSTOMER_SIGNED";
            return { orderDetails };
          });

          // this.setState({showToast : true});
        })
        .catch((error) => {})
        .finally(() => {
          // this.setState({showToast : true});

          setTimeout(() => {
            this.setState({ showToast: false });
            this.closeSignatureDialog();
          }, 2000);
          // always executed
        });
    }
  };

  handleSignatureChange(event) {
    this.setState({ customerSignature: event });
    this.updateCustomerSignature(event);
  }

  openSignatureDialog = () => {
    this.setState({ showSignatureDialog: true });
  };
  closeSignatureDialog = () => {
    this.setState({ showSignatureDialog: false });
  };

  print = () => {
    this.setState({ formCanvasDataUrl: true });
  };
  downloadPDF = (csvURL) => {
    let tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Order" + Date.now().toString() + ".pdf");
    tempLink.click();
    tempLink.remove();
    this.setState({ formCanvasDataUrl: null });
  };

  handleChange(event) {
    let value = event.target.value;
    let id = event.target.id;
  }

  renderPDF() {
    return (
      this.state.signature.signatureLoaded ||
      (this.state.orderDetails && this.state.orderDetails.data)
    );
  }

  send(pdfBase64data) {
    let data = { pdf: pdfBase64data };
    let pathname = window.location.pathname.split("/customerForm/");

    if (pathname.length == 2) {
      data["id"] = pathname[1];
      data["messageType"] = "CUSTOMER";
      data["destination"] = this.state.orderDetails.data.CustomerDetails.email;

      api.orders
        .sendPDF(data)
        .then((response) => {
          this.setState({ disableSendButton: true });
        })
        .catch((error) => {
          this.setState({ disableSendButton: false });
        })
        .finally(() => {
          setTimeout(() => {}, 2000);
          // always executed
        });
    }
  }

  render() {
    const disabled = false;
    const labels = this.labels;
    var pdfDataToSend = undefined;
    var disableSendButton = this.state.disableSendButton;
    return (
      <div
        style={{
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          direction: "rtl",
          textAlign: "right",
        }}
      >
        <Form style={{ marginTop: "10px" }}>
          {/* <FormGroup row> */}
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Label
              style={{
                fontSize: 20,
              }}
            >
              עמוד לקוח
            </Label>
          </div>

          {this.renderPDF() ? (
            // <div id="def">
            <PDFViewer
              width={"100%"}
              height={"580"}
              children={
                <PDF
                  key="pdfWiewer"
                  companyDetails={this.state.companyDetails}
                  order={this.state.orderId}
                  notes={this.state.orderDetails.data.Notes}
                  sinkDetails={this.state.orderDetails.data.SinkDetails}
                  carpentry={this.state.orderDetails.data.CarpentryDetails}
                  stove={this.state.orderDetails.data.Stove}
                  technicalQuestions={
                    this.state.orderDetails.data.TechnicalQuestions
                  }
                  customerDetails={this.state.orderDetails.data.CustomerDetails}
                  drills={this.state.orderDetails.data.DrillingDetails}
                  items={this.state.orderDetails.data.Items}
                  totalPrice={this.totalPrice}
                  customerSignature={this.state.customerSignature}
                  allItems={this.allItems}
                  allCompanies={this.allCompanies}
                  allItemTypes={this.allItemTypes}
                  payments={this.state.orderDetails.data.Payments}
                />
              }
            ></PDFViewer>
          ) : // </div>
          null}

          {this.renderPDF() ? (
            <BlobProvider
              document={
                <PDF
                  key="blobForPdf"
                  companyDetails={this.state.companyDetails}
                  order={this.state.orderId}
                  notes={this.state.orderDetails.data.Notes}
                  sinkDetails={this.state.orderDetails.data.SinkDetails}
                  carpentry={this.state.orderDetails.data.CarpentryDetails}
                  stove={this.state.orderDetails.data.Stove}
                  technicalQuestions={
                    this.state.orderDetails.data.TechnicalQuestions
                  }
                  customerDetails={this.state.orderDetails.data.CustomerDetails}
                  drills={this.state.orderDetails.data.DrillingDetails}
                  items={this.state.orderDetails.data.Items}
                  totalPrice={this.totalPrice}
                  customerSignature={this.state.customerSignature}
                  allItems={this.allItems}
                  allCompanies={this.allCompanies}
                  allItemTypes={this.allItemTypes}
                  payments={this.state.orderDetails.data.Payments}
                />
              }
            >
              {({ blob, url, loading, error }) => {
                // Do whatever you need with blob here
                if (blob != null) {
                  var reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = () => {
                    var base64data = reader.result;
                    pdfDataToSend = base64data;
                  };
                  // this.sendPDF(blob);
                }
                return <div></div>;
              }}
            </BlobProvider>
          ) : null}

          {/* </FormGroup> */}
          {this.state.orderDetails &&
          this.state.orderDetails.status == "WAIT_FOR_CUSTOMER_SIGNATURE" ? (
            <FormGroup row>
              <Col md={{ size: 2 }}>
                <Label style={{ marginTop: "10px" }}>
                  {labels["signature"]}
                </Label>
              </Col>

              <Col md={{ size: 2 }}>
                <div>
                  {this.state.customerSignature ? (
                    <img
                      style={{ width: "100px", height: "40px" }}
                      src={this.state.customerSignature}
                    />
                  ) : null}
                </div>
              </Col>
              <Col>
                <Button color="primary" onClick={this.openSignatureDialog}>
                  הוסף חתימה
                </Button>
              </Col>

              <Col>
                <Signature
                  onChange={this.handleSignatureChange}
                  showSignatureDialog={this.state.showSignatureDialog}
                  closeSignatureDialog={this.closeSignatureDialog}
                ></Signature>
              </Col>
            </FormGroup>
          ) : null}
          {/* <FormGroup row>
            <Col >
              {this.state.signature.signatureLoaded ?
                <img src={`data:image/png;base64,${this.state.signature.signatureData}`} /> : null
              }
            </Col>
          </FormGroup> */}
        </Form>
        <div
          style={{
            textAlign: "center",
          }}
        >
          {/* <Col> */}
          <Button
            disabled={disableSendButton}
            color="success"
            onClick={() => this.send(pdfDataToSend)}
          >
            {disableSendButton ? (
              <Label> {labels.sent} </Label>
            ) : (
              <Label>{labels.send} </Label>
            )}
          </Button>
        </div>
      </div>
    );
  }
}

export default CustomerForm;

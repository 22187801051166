import React, { Component } from "react";
import { Button, Col, Form, FormGroup, Label } from "reactstrap";

import { FilesSection } from "../Files/files";
import { GlobalContext } from "../Menu/global-context";
import Payment from "./payment";

class Payments extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.addPayment = this.addPayment.bind(this);
    this.removePayment = this.removePayment.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.prettifyPrice = this.prettifyPrice.bind(this);
    this.convertUndefinedToZero = this.convertUndefinedToZero.bind(this);

    this.labels = Object.assign({}, require("./labels.json"));

    this.state = {
      details: {
        payments: [
          {
            terms: undefined,
            total: undefined,
            date: undefined,
            number: undefined,
            identificationNumber: undefined,
            note: undefined,
          },
        ],
        filesData: { files: [] },
      },
    };
  }

  loadData(data) {
    this.setState((prevState) => {
      let details = Object.assign({}, prevState.details);
      if (Array.isArray(data)) {
        // This is backwards compatability to when payments were [] and not an {}
        data = { payments: data };
      }
      if (data != undefined) {
        // for the case a payments is null
        data.payments = data.payments.filter((d) => d !== null);
        details = data;
      }
      this.props.onChange("payments", details);
      return { details };
    });
  }

  handleChange(event) {
    let value = event.target.value;
    let id = event.target.id;
    let index = event.target.index;

    this.setState((prevState) => {
      let details = Object.assign({}, prevState.details);
      details.payments[index][id] = value;
      this.props.onChange("payments", details);
      return { details };
    });
  }

  handleFiles(allFiles) {
    this.setState((prevState) => {
      let details = Object.assign({}, prevState.details);
      if (!details.filesData) {
        details.filesData = { files: [] };
      }
      details.filesData = {
        files: allFiles,
      };
      this.props.onChange("payments", details);
      return { details };
    });
  }

  addPayment() {
    let newPayment = {
      terms: undefined,
      total: undefined,
      date: undefined,
      number: undefined,
      identificationNumber: undefined,
      note: undefined,
    };
    //   this.state.payments.push(newPayment);

    this.setState((prevState) => {
      // let payments = Object.assign([], prevState.payments);
      let details = Object.assign({}, prevState.details);
      details.payments.push(newPayment);
      return { details };
    });
  }

  removePayment(event) {
    const index = parseInt(event.target.id);

    this.setState((prevState) => {
      // let payments = Object.assign([], prevState.payments);
      let details = Object.assign({}, prevState.details);
      delete details.payments[index];
      return { details };
    });
  }

  componentDidMount() {
    let totalPrice = this.props.totalPrice;
  }

  componentDidUpdate() {
    let totalPrice = this.props.totalPrice;
  }

  getPrice(price, length) {
    return length !== 0
      ? Math.round((length * price + Number.EPSILON) * 100) / 100
      : Math.round((price + Number.EPSILON) * 100) / 100;
  }

  prettifyPrice(price) {
    return Math.round(price);
  }
  convertUndefinedToZero(price) {
    return price != undefined ? price : 0;
  }

  render() {
    const disabled = false;
    const labels = this.labels;
    let payments = this.state.details.payments;

    let elements = payments.map((payment, index) => {
      return (
        <FormGroup key={index} row style={{ marginRight: "0px" }}>
          <Payment
            key={index}
            index={index}
            terms={payment.terms}
            total={payment.total}
            date={payment.date}
            number={payment.number}
            identificationNumber={payment.identificationNumber}
            note={payment.note}
            onChange={this.handleChange}
          />
          <Button
            id={index}
            onClick={this.removePayment}
            style={{ height: "50%", margin: "30px 15px 0 0" }}
          >
            {labels.removePayment}
          </Button>
        </FormGroup>
      );
    });

    let items = this.props.totalPrice;
    let totalPrice = 0;

    items.map((item) => (totalPrice += this.getPrice(item.price, item.length)));

    let totalPriceAfterTax = this.prettifyPrice(
      this.context.getVat(totalPrice, this.context.orderDate),
    );
    let sum = totalPriceAfterTax;
    payments.map(
      (payment) =>
        (sum -= this.getPrice(this.convertUndefinedToZero(payment.total), 1)),
    );

    return (
      <div>
        <Form style={{ marginTop: "20px" }}>
          {elements}

          <Button onClick={this.addPayment}>{labels.addPayment}</Button>

          <Col style={{ textAlign: "left", paddingLeft: "110px" }}>
            <Label style={{ paddingLeft: "10px" }}>
              {labels.totalBeforeTax}
            </Label>
            <Label>{totalPrice}</Label>
          </Col>
          <Col style={{ textAlign: "left", paddingLeft: "110px" }}>
            <Label style={{ paddingLeft: "10px" }}>
              {labels.totalAfterTax}
            </Label>
            <Label>{totalPriceAfterTax}</Label>
          </Col>

          <Col style={{ textAlign: "left", paddingLeft: "110px" }}>
            <Label style={{ paddingLeft: "10px" }}>{labels.sum}</Label>
            <Label>{sum}</Label>
          </Col>
        </Form>
        <FilesSection
          filesData={this.state.details.filesData}
          handleFiles={this.handleFiles}
        />
      </div>
    );
  }
}

export default Payments;

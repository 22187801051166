import classnames from "classnames";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Toast,
  ToastBody,
} from "reactstrap";
import Carpentry from "../Carpentry/carpentry";
import Customer from "../Customer/customer";
import Drilling from "../Drilling/drilling";
import { transformFilesDataToAppFils } from "../Files/files";
import Finish from "../Finish/finish";
import ItemsTable from "../ItemsTable/items";
import Notes from "../Notes/notes";
import Payments from "../Payments/payments";
import Sink from "../Sink/sink";
import Stove from "../Stove/stove";
import TechnicalQuestions from "../TechnicalQuestions/technicalQuestions";

import api from "../../Services/api";
import { getVat, GlobalContext } from "./global-context";
import { SaveContext } from "./save-context";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.childChanged = this.childChanged.bind(this);
    this.saveOrder = this.saveOrder.bind(this);

    this.state = {
      activeTab: 1,
      customerDetails: {},
      drillingDetails: [],
      carpentryDetails: {},
      sinkDetails: {},
      items: [],
      cards: [],
      notes: {},
      stove: {},
      technicalQuestions: {},
      payments: {},
      finish: {},
      showToast: false,
      redirectToOrders: false,
      clearSaveButtons: "",
      isSubmitting: false,
      global: { orderDate: new Date().toISOString() },
    };

    this.labels = Object.assign({}, require("./labels.json"));

    this.CustomerElement = React.createRef();
    this.ItemsElement = React.createRef();
    this.SinkElement = React.createRef();
    this.CarpentryElement = React.createRef();
    this.DrillsElement = React.createRef();
    this.TechnicalQuestionsElement = React.createRef();
    this.NotesElement = React.createRef();
    this.StoveElement = React.createRef();
    this.FinishElement = React.createRef();
    this.PaymentsElement = React.createRef();
  }

  loadData(data, seller_id, surveyor_id, status, paymentContact) {
    this.setState({ global: { orderDate: data.CustomerDetails.date } });
    this.CustomerElement.current.loadData(data.CustomerDetails);
    this.ItemsElement.current.loadData(data.Items);
    this.SinkElement.current.loadData(data.SinkDetails);
    this.CarpentryElement.current.loadData(data.CarpentryDetails);
    this.DrillsElement.current.loadData(data.DrillingDetails);
    this.TechnicalQuestionsElement.current.loadData(data.TechnicalQuestions);
    this.StoveElement.current.loadData(data.Stove);
    this.NotesElement.current.loadData(data.Notes);
    this.FinishElement.current.loadData(
      seller_id,
      surveyor_id,
      status,
      paymentContact,
      data.finish,
    );
    this.PaymentsElement.current.loadData(data.Payments);
  }

  componentDidMount() {
    let pathname = window.location.pathname.split("/order/");
    if (pathname.length == 2) {
      api.orders
        .get({
          id: pathname[1],
        })
        .then((response) => {
          let order = response.data.orders[0];
          let data = order.data;
          transformFilesDataToAppFils(data);
          this.loadData(
            data,
            order.seller_id,
            order.surveyor_id,
            order.status,
            order.payment_contact,
          );
        })
        .catch(function (error) {})
        .finally(function () {
          // always executed
        });
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  childChanged(key, value) {
    switch (key) {
      case "customerDetails":
        this.setState({ customerDetails: value });
        break;
      case "drillingDetails":
        this.setState({ drillingDetails: value });
        break;
      case "carpentryDetails":
        this.setState({ carpentryDetails: value });
        break;
      case "sinkDetails":
        this.setState({ sinkDetails: value });
        break;
      case "items":
        this.setState({ items: value });
        //  this.setState({cards: value[1]});
        break;
      case "notes":
        this.setState({ notes: value });
        break;
      case "stove":
        this.setState({ stove: value });
        break;
      case "technicalQuestions":
        this.setState({ technicalQuestions: value });
        break;
      case "payments":
        this.setState({ payments: value });
        break;
      case "finish":
        this.setState({ finish: value });
        break;
    }
  }

  saveOrder() {
    if (this.state.isSubmitting) {
      return;
    }
    this.setState({ isSubmitting: true });
    this.setState({ clearSaveButtons: crypto.randomUUID() });
    this.setState({ showToast: false });

    let stateCopy = Object.assign({}, this.state);

    let items = this.state.items;

    let newItems = [];
    for (var i = 0; i < items.length; i++) {
      if (items[i]) {
        newItems.push(items[i]);
      }
    }
    stateCopy["items"] = newItems;

    //  CustomerDetails={this.state.customerDetails}
    //   DrillingDetails={this.state.drillingDetails}
    //   SinkDetails={this.state.sinkDetails}
    //   CarpentryDetails={this.state.carpentryDetails}
    //   Items={this.state.items}
    //   // Cards={this.state.cards}
    //   Notes={this.state.notes}
    //   Stove={this.state.stove}
    //   TechnicalQuestions={this.state.technicalQuestions}
    //   Payments={this.state.payments}

    if (isNaN(parseInt(stateCopy.finish.seller_id))) {
      stateCopy.finish.seller_id = null;
    } else {
      stateCopy.finish.seller_id = parseInt(stateCopy.finish.seller_id);
    }
    if (isNaN(parseInt(stateCopy.finish.surveyor_id))) {
      stateCopy.finish.surveyor_id = null;
    } else {
      stateCopy.finish.surveyor_id = parseInt(stateCopy.finish.surveyor_id);
    }
    let data = {
      state: {
        CustomerDetails: stateCopy.customerDetails,
        DrillingDetails: stateCopy.drillingDetails,
        SinkDetails: stateCopy.sinkDetails,
        CarpentryDetails: stateCopy.carpentryDetails,
        Items: stateCopy.items,
        Notes: stateCopy.notes,
        Stove: stateCopy.stove,
        TechnicalQuestions: stateCopy.technicalQuestions,
        Payments: stateCopy.payments,
        finish: stateCopy.finish,
      },
    };
    let pathname = window.location.pathname.split("/order/");
    if (pathname.length == 2) {
      const id = pathname[1];
      const formData = this.prepareFilesAndDataForSubmit({ id, data });
      api.orders
        .update({ id, formData })
        .then((response) => {
          this.setState({ showToast: true });
        })
        .catch((error) => {})
        .finally(() => {
          setTimeout(() => {
            this.setState({ showToast: false });
            this.setState({ isSubmitting: false });
          }, 2000);
          // always executed
        });
    } else {
      const formData = this.prepareFilesAndDataForSubmit({
        id: undefined,
        data,
      });
      api.orders
        .post({ formData })
        .then((response) => {
          this.setState({ showToast: true });
        })
        .catch((error) => {})
        .finally(() => {
          setTimeout(() => {
            this.setState({ showToast: false, redirectToOrders: true });
            this.setState({ isSubmitting: false });
          }, 2000);
        });
    }
  }

  prepareFilesAndDataForSubmit({ id, data }) {
    // Files here are the File subclass "AppFile"
    const dataClone = JSON.parse(JSON.stringify(data));
    // Do not change between data and dataClone as dataClone does not clone properly the AppFile
    dataClone["id"] = id;
    const formData = new FormData();
    for (const [key, value] of Object.entries(data.state)) {
      console.log(`${key}: ${value}`);
      if ("filesData" in value) {
        const files = value.filesData.files;
        dataClone.state[key].filesData = { files: [] };
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          formData.append(`${key}__files[]`, file, file.id);
          dataClone.state[key].filesData.files.push(file.prepareForSend());
        }
      }
    }
    formData.append("data", JSON.stringify(dataClone));
    return formData;
  }

  cancelOrder = () => {
    this.setState({ redirectToOrders: true });
  };

  renderRedirectToOrders = () => {
    if (this.state.redirectToOrders) {
      return <Redirect to="/orders" />;
    }
  };

  render() {
    let successToast = (
      <div
        className="bg-success rounded"
        style={{ position: "fixed", top: "10px", left: "10px" }}
      >
        <Toast>
          <ToastBody>{this.labels.orderSavedSuccessfully}</ToastBody>
        </Toast>
      </div>
    );
    let pathname = window.location.pathname.split("/order/");

    let orderID = pathname.length == 2 ? pathname[1] : null;

    let customerName = `${this.state.customerDetails.firstName} ${this.state.customerDetails.lastName}`;
    let orderIdToast = (
      <div className="bg-info rounded">
        <Toast>
          <ToastBody>
            <b>{this.labels.orderID}</b> {orderID} |{" "}
            <b>{this.labels.customerName}</b> {customerName}
          </ToastBody>
        </Toast>
      </div>
    );

    let activeTab = this.state.activeTab;

    let navItems = this.labels.tabs.map((tab, index) => {
      return (
        <NavItem key={index}>
          <NavLink
            className={classnames({ active: activeTab === index + 1 })}
            onClick={() => {
              this.toggle(index + 1);
            }}
          >
            {tab.label}
          </NavLink>
        </NavItem>
      );
    });

    let tabPanes = this.labels.tabs.map((tab, index) => {
      switch (tab.key) {
        case "CUSTOMER_DETAILS":
          return (
            <TabPane key={index} tabId={index + 1}>
              <Customer
                ref={this.CustomerElement}
                onChange={this.childChanged}
              />
            </TabPane>
          );

        case "ITEMS":
          return (
            <TabPane key={index} tabId={index + 1}>
              <ItemsTable
                ref={this.ItemsElement}
                onChange={this.childChanged}
              />
            </TabPane>
          );

        case "TECHNICAL_QUESTIONS":
          return (
            <TabPane key={index} tabId={index + 1}>
              <TechnicalQuestions
                ref={this.TechnicalQuestionsElement}
                onChange={this.childChanged}
              />
            </TabPane>
          );

        case "CARPENTRY":
          return (
            <TabPane key={index} tabId={index + 1}>
              <Carpentry
                ref={this.CarpentryElement}
                onChange={this.childChanged}
              />
            </TabPane>
          );

        case "DRILLS":
          return (
            <TabPane key={index} tabId={index + 1}>
              <Drilling ref={this.DrillsElement} onChange={this.childChanged} />
            </TabPane>
          );

        case "SINK":
          return (
            <TabPane key={index} tabId={index + 1}>
              <Sink ref={this.SinkElement} onChange={this.childChanged} />
            </TabPane>
          );

        case "STOVE":
          return (
            <TabPane key={index} tabId={index + 1}>
              <Stove ref={this.StoveElement} onChange={this.childChanged} />
            </TabPane>
          );

        case "NOTES":
          return (
            <TabPane key={index} tabId={index + 1}>
              <Notes ref={this.NotesElement} onChange={this.childChanged} />
            </TabPane>
          );
        case "PAYMENTS":
          return (
            <TabPane key={index} tabId={index + 1}>
              <Payments
                ref={this.PaymentsElement}
                onChange={this.childChanged}
                totalPrice={this.state.items}
              />
            </TabPane>
          );
        case "FINISH":
          return (
            <TabPane key={index} tabId={index + 1}>
              <Finish ref={this.FinishElement} onChange={this.childChanged} />
            </TabPane>
          );
      }
    });

    return (
      <GlobalContext.Provider
        value={{ orderDate: this.state.global.orderDate, getVat }}
      >
        <SaveContext.Provider
          value={{
            saveOrder: this.saveOrder,
            clearSaveButtons: this.state.clearSaveButtons,
          }}
        >
          <div
            style={{
              width: "98%",
              marginLeft: "auto",
              marginRight: "auto",
              direction: "rtl",
              textAlign: "right",
            }}
          >
            <Nav style={{ paddingRight: 0 }} tabs>
              {navItems}
              <Row style={{ textAlign: "left" }}>
                <Col md={{ size: "auto" }}>{orderID ? orderIdToast : null}</Col>
              </Row>
              <Row style={{ textAlign: "left", marginRight: "auto" }}>
                <Col md={{ size: "auto" }}>
                  {this.state.showToast ? successToast : null}
                </Col>

                <Col
                  md={{ size: "auto" }}
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={this.saveOrder}
                  >
                    שמירה
                  </Button>
                </Col>
                <Col
                  md={{ size: "auto" }}
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Button
                    style={{ backgroundColor: "red" }}
                    onClick={this.cancelOrder}
                  >
                    ביטול
                  </Button>
                </Col>
                {this.renderRedirectToOrders()}
              </Row>
            </Nav>

            <TabContent activeTab={activeTab}>
              {tabPanes}
              {/* <TabPane tabId={11}> */}
              {/* <Finish 
          ref={this.FinishElement}
          CustomerDetails={this.state.customerDetails} 
          DrillingDetails={this.state.drillingDetails}
          SinkDetails={this.state.sinkDetails}
          CarpentryDetails={this.state.carpentryDetails}
          Items={this.state.items}
          // Cards={this.state.cards}
          Notes={this.state.notes}
          Stove={this.state.stove}
          TechnicalQuestions={this.state.technicalQuestions}
          Payments={this.state.payments}
          /> */}
              {/* </TabPane> */}
            </TabContent>
          </div>
        </SaveContext.Provider>
      </GlobalContext.Provider>
    );
  }
}

export default Menu;
